import Swal from "sweetalert2";

Turbo.setConfirmMethod((message, element) => {
  const locale = document.body.dataset.locale || "fr";

  // Not yet possible to have custom title: https://github.com/hotwired/turbo/issues/811
  // const title = this.element.dataset.title;

  return new Promise((resolve) => {
    Swal.fire({
      cancelButtonText: locale === "fr" ? "Non" : "No",
      confirmButtonText: locale === "fr" ? "Oui" : "Yes",
      html: message || "",
      showCancelButton: true,
      title: locale === "fr" ? "Êtes-vous sûr ?" : "Are you sure?",
    }).then((result) => {
      if (result.value) return resolve(true);
      resolve(false);
    });
  });
});
