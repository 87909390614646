import TomSelect from "tom-select";

/**
 * Implement Tom-Select support with
 * 1. AJAX Support
 * 2. Multiple element support
 * 3. Dynamic element support
 */

export const loadTomSelect = (elem, ajax) => {
  const ajaxExtraConfig = {
    labelField: "text",
    load: (query, callback) => {
      const url = `${elem.dataset.url}?query=${encodeURIComponent(query)}`;
      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          callback(json.items);
        })
        .catch(() => {
          callback();
        });
    },
    preload: true,
    render: undefined,
    searchField: "text",
    valueField: "value",
  };

  const createConfig = { create: elem.dataset.dynamic == "true" };

  const renderOverrides = {
    item: (data, escape) => {
      const customClass = data.$option ? data.$option.classList.toString() : "";
      return `<div class="${customClass}">${escape(data.text)}</div>`;
    },
  };

  let ts;
  // Multiple or single selection
  if (elem.getAttribute("multiple")) {
    ts = new TomSelect(elem, {
      plugins: ["remove_button"],
      render: renderOverrides,
      ...createConfig,
      ...(ajax && ajaxExtraConfig),
    });
  } else {
    ts = new TomSelect(elem, { render: renderOverrides, ...createConfig, ...(ajax && ajaxExtraConfig) });
  }
  // Behavior improvement for multiple
  ts.on("item_add", function () {
    if (elem.multiple) {
      this.input.nextElementSibling.querySelector("input").value = "";
      this.refreshOptions();
    }
  });
};
